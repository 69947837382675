<template>
	<div class="align-center justify-center chat-count" :class="{ 'd-none': this.msgCount == 0 }">
		<p>{{ msgCount }}</p>
	</div>
</template>

<script>
import firebase from 'firebase/app'
import { FIREBASE_COLLECTION_NAME_PREFIX } from '../config/variable'

export default {
	props: {
		id: {
			type: String,
			default: '',
		},
		chatOpen: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			count: '',
		}
	},

	async mounted() {
		this.getCount()
	},

	computed: {
		msgCount() {
			if (this.id == this.$store.state.chathaggle.collection_id && this.chatOpen) {
				return 0
			} else {
				this.getCount()
			}
			return this.count
		},
	},

	methods: {
		async getCount() {
			let snapshot = await firebase
				.firestore()
				.collection(FIREBASE_COLLECTION_NAME_PREFIX + 'messages')
				.doc(this.id)
			snapshot.onSnapshot((doc) => {
				this.count = doc.data().new_message_count ? doc.data().new_message_count : 0
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.chat-count {
	text-align: center;
	background-color: #f15757;
	border: 2px solid #ffffff;
	border-radius: 100%;
	width: 20px;
	height: 20px;
	position: absolute;
	top: -15px;
	right: -8px;
	display: flex;

	p {
		color: #f7f9fc;
		font-size: 12px;
		line-height: 14px;
		width: 16px;
		text-align: center;
		margin: 0;
		font-family: 'Inter-SemiBold';
		// padding-top: 2px;
	}
}
</style>
